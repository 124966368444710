<script setup lang="ts">
import SpellHeader from '~/components/spell/SpellHeader.vue';
import SpellRunDetails from '~/components/spell/SpellRunDetails.vue';

const canvasStore = useCanvasStore();
const spellStore = useSpellsStore();
const { run, liveGraph } = storeToRefs(spellStore);

onBeforeMount(() => {
  run.value = undefined;
});

onMounted(() => spellStore.validateSpell());
</script>
<template>
  <NuxtLayout name="spell-tab">
    <SpellHeader />
    <div class="flex flex-col w-full my-m items-center justify-start gap-5">
      <!-- INPUT -->
      <SpellFormInput
        :run="run"
        :graph="liveGraph"
        :error-count="Object.values(canvasStore.errors)?.flat()?.length ?? 0"
      />
      <div v-if="run" class="contents">
        <!-- RESULTS -->
        <UFormGroup
          label="Results"
          size="xl"
          description="See what outputs the workflow produced"
          :ui="{
            wrapper: 'w-full',
          }"
        >
          <SpellFormOutput :run="run" :graph="liveGraph" />
        </UFormGroup>
        <!-- SPELL RUN DETAILS -->
        <UFormGroup
          label="Steps"
          size="xl"
          description="See what steps were used during the spell execution"
          :ui="{
            container: 'flex flex-col gap-2',
            wrapper: 'w-full',
          }"
        >
          <SpellRunDetails :run="run" :graph="liveGraph" />
        </UFormGroup>
      </div>
    </div>
  </NuxtLayout>
</template>
